/* Automatically generated file, do not update manually */
    import type { Provider } from '@ethersproject/abstract-provider';
import { Contract, Signer } from 'ethers';
import { useMemo } from 'react';

import abi from 'libs/contracts/generated/infos/abis/VaiVault.json';
import { VaiVault } from 'libs/contracts/generated/infos/contractTypes';
import { getUniqueContractAddress } from 'libs/contracts/utilities/getUniqueContractAddress';
import { useChainId, useProvider, useSigner } from 'libs/wallet';
import { ChainId } from 'types';

export { abi as VaiVaultAbi } from 'libs/contracts/generated/infos/abis/VaiVault';

interface GetVaiVaultContractAddressInput {
  chainId: ChainId;
}

export const getVaiVaultContractAddress = ({
  chainId,
}: GetVaiVaultContractAddressInput) =>
  getUniqueContractAddress({ name: 'VaiVault', chainId });

export const useGetVaiVaultContractAddress = () => {
  const { chainId } = useChainId();

  return useMemo(
    () =>
      getVaiVaultContractAddress({
        chainId,
      }),
    [chainId],
  );
};

interface GetVaiVaultContractInput {
  chainId: ChainId;
  signerOrProvider: Signer | Provider;
}

export const getVaiVaultContract = ({
  chainId,
  signerOrProvider,
}: GetVaiVaultContractInput) => {
  const address = getVaiVaultContractAddress({ chainId });
  return address ? (new Contract(address, abi, signerOrProvider) as VaiVault) : undefined;
};

interface UseGetVaiVaultContractInput {
  passSigner?: boolean;
  chainId?: ChainId;
}

export const useGetVaiVaultContract = (input?: UseGetVaiVaultContractInput) => {
  const { chainId: currentChainId } = useChainId();
  const chainId = input?.chainId || currentChainId;

  const { provider } = useProvider({ chainId });
  const { signer } = useSigner({ chainId });
  const signerOrProvider = input?.passSigner ? signer : provider;

  return useMemo(
    () =>
      signerOrProvider
        ? getVaiVaultContract({
            chainId,
            signerOrProvider,
          })
        : undefined,
    [signerOrProvider, chainId],
  );
};

    