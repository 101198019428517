/* Automatically generated file, do not update manually */
    import type { Provider } from '@ethersproject/abstract-provider';
import { Contract, Signer } from 'ethers';
import { useMemo } from 'react';

import abi from 'libs/contracts/generated/infos/abis/Bep20.json';
import { Bep20 } from 'libs/contracts/generated/infos/contractTypes';
import { useProvider, useSigner } from 'libs/wallet';
import { ChainId } from 'types';

export { abi as Bep20Abi } from 'libs/contracts/generated/infos/abis/Bep20';

interface GetBep20ContractInput {
  address: string;
  signerOrProvider: Signer | Provider;
}

export const getBep20Contract = ({ signerOrProvider, address }: GetBep20ContractInput) =>
  new Contract(address, abi, signerOrProvider) as Bep20;

interface UseGetBep20ContractInput {
  passSigner: boolean;
  address: string;
  chainId?: ChainId;
}

export const useGetBep20Contract = ({
  passSigner = false,
  address,
  chainId,
}: UseGetBep20ContractInput) => {
  const { provider } = useProvider({ chainId });
  const { signer } = useSigner({ chainId });
  const signerOrProvider = passSigner ? signer : provider;

  return useMemo(
    () =>
      signerOrProvider
        ? getBep20Contract({
            address,
            signerOrProvider,
          })
        : undefined,
    [signerOrProvider, address],
  );
};

    