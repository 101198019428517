/* Automatically generated file, do not update manually */
    import type { Provider } from '@ethersproject/abstract-provider';
import { Contract, Signer } from 'ethers';
import { useMemo } from 'react';

import abi from 'libs/contracts/generated/infos/abis/VenusLens.json';
import { VenusLens } from 'libs/contracts/generated/infos/contractTypes';
import { getUniqueContractAddress } from 'libs/contracts/utilities/getUniqueContractAddress';
import { useChainId, useProvider, useSigner } from 'libs/wallet';
import { ChainId } from 'types';

export { abi as VenusLensAbi } from 'libs/contracts/generated/infos/abis/VenusLens';

interface GetVenusLensContractAddressInput {
  chainId: ChainId;
}

export const getVenusLensContractAddress = ({
  chainId,
}: GetVenusLensContractAddressInput) =>
  getUniqueContractAddress({ name: 'VenusLens', chainId });

export const useGetVenusLensContractAddress = () => {
  const { chainId } = useChainId();

  return useMemo(
    () =>
      getVenusLensContractAddress({
        chainId,
      }),
    [chainId],
  );
};

interface GetVenusLensContractInput {
  chainId: ChainId;
  signerOrProvider: Signer | Provider;
}

export const getVenusLensContract = ({
  chainId,
  signerOrProvider,
}: GetVenusLensContractInput) => {
  const address = getVenusLensContractAddress({ chainId });
  return address ? (new Contract(address, abi, signerOrProvider) as VenusLens) : undefined;
};

interface UseGetVenusLensContractInput {
  passSigner?: boolean;
  chainId?: ChainId;
}

export const useGetVenusLensContract = (input?: UseGetVenusLensContractInput) => {
  const { chainId: currentChainId } = useChainId();
  const chainId = input?.chainId || currentChainId;

  const { provider } = useProvider({ chainId });
  const { signer } = useSigner({ chainId });
  const signerOrProvider = input?.passSigner ? signer : provider;

  return useMemo(
    () =>
      signerOrProvider
        ? getVenusLensContract({
            chainId,
            signerOrProvider,
          })
        : undefined,
    [signerOrProvider, chainId],
  );
};

    