/* Automatically generated file, do not update manually */
    import type { Provider } from '@ethersproject/abstract-provider';
import { Contract, Signer } from 'ethers';
import { useMemo } from 'react';

import abi from 'libs/contracts/generated/infos/abis/XVSProxyOFTDest.json';
import { XVSProxyOFTDest } from 'libs/contracts/generated/infos/contractTypes';
import { getUniqueContractAddress } from 'libs/contracts/utilities/getUniqueContractAddress';
import { useChainId, useProvider, useSigner } from 'libs/wallet';
import { ChainId } from 'types';

export { abi as XVSProxyOFTDestAbi } from 'libs/contracts/generated/infos/abis/XVSProxyOFTDest';

interface GetXVSProxyOFTDestContractAddressInput {
  chainId: ChainId;
}

export const getXVSProxyOFTDestContractAddress = ({
  chainId,
}: GetXVSProxyOFTDestContractAddressInput) =>
  getUniqueContractAddress({ name: 'XVSProxyOFTDest', chainId });

export const useGetXVSProxyOFTDestContractAddress = () => {
  const { chainId } = useChainId();

  return useMemo(
    () =>
      getXVSProxyOFTDestContractAddress({
        chainId,
      }),
    [chainId],
  );
};

interface GetXVSProxyOFTDestContractInput {
  chainId: ChainId;
  signerOrProvider: Signer | Provider;
}

export const getXVSProxyOFTDestContract = ({
  chainId,
  signerOrProvider,
}: GetXVSProxyOFTDestContractInput) => {
  const address = getXVSProxyOFTDestContractAddress({ chainId });
  return address ? (new Contract(address, abi, signerOrProvider) as XVSProxyOFTDest) : undefined;
};

interface UseGetXVSProxyOFTDestContractInput {
  passSigner?: boolean;
  chainId?: ChainId;
}

export const useGetXVSProxyOFTDestContract = (input?: UseGetXVSProxyOFTDestContractInput) => {
  const { chainId: currentChainId } = useChainId();
  const chainId = input?.chainId || currentChainId;

  const { provider } = useProvider({ chainId });
  const { signer } = useSigner({ chainId });
  const signerOrProvider = input?.passSigner ? signer : provider;

  return useMemo(
    () =>
      signerOrProvider
        ? getXVSProxyOFTDestContract({
            chainId,
            signerOrProvider,
          })
        : undefined,
    [signerOrProvider, chainId],
  );
};

    