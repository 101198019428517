/* Automatically generated file, do not update manually */
    import type { Provider } from '@ethersproject/abstract-provider';
import { Contract, Signer } from 'ethers';
import { useMemo } from 'react';

import abi from 'libs/contracts/generated/infos/abis/Multicall3.json';
import { Multicall3 } from 'libs/contracts/generated/infos/contractTypes';
import { getUniqueContractAddress } from 'libs/contracts/utilities/getUniqueContractAddress';
import { useChainId, useProvider, useSigner } from 'libs/wallet';
import { ChainId } from 'types';

export { abi as Multicall3Abi } from 'libs/contracts/generated/infos/abis/Multicall3';

interface GetMulticall3ContractAddressInput {
  chainId: ChainId;
}

export const getMulticall3ContractAddress = ({
  chainId,
}: GetMulticall3ContractAddressInput) =>
  getUniqueContractAddress({ name: 'Multicall3', chainId });

export const useGetMulticall3ContractAddress = () => {
  const { chainId } = useChainId();

  return useMemo(
    () =>
      getMulticall3ContractAddress({
        chainId,
      }),
    [chainId],
  );
};

interface GetMulticall3ContractInput {
  chainId: ChainId;
  signerOrProvider: Signer | Provider;
}

export const getMulticall3Contract = ({
  chainId,
  signerOrProvider,
}: GetMulticall3ContractInput) => {
  const address = getMulticall3ContractAddress({ chainId });
  return address ? (new Contract(address, abi, signerOrProvider) as Multicall3) : undefined;
};

interface UseGetMulticall3ContractInput {
  passSigner?: boolean;
  chainId?: ChainId;
}

export const useGetMulticall3Contract = (input?: UseGetMulticall3ContractInput) => {
  const { chainId: currentChainId } = useChainId();
  const chainId = input?.chainId || currentChainId;

  const { provider } = useProvider({ chainId });
  const { signer } = useSigner({ chainId });
  const signerOrProvider = input?.passSigner ? signer : provider;

  return useMemo(
    () =>
      signerOrProvider
        ? getMulticall3Contract({
            chainId,
            signerOrProvider,
          })
        : undefined,
    [signerOrProvider, chainId],
  );
};

    