/* Automatically generated file, do not update manually */
    import type { Provider } from '@ethersproject/abstract-provider';
import { Contract, Signer } from 'ethers';
import { useMemo } from 'react';

import abi from 'libs/contracts/generated/infos/abis/XvsVault.json';
import { XvsVault } from 'libs/contracts/generated/infos/contractTypes';
import { getUniqueContractAddress } from 'libs/contracts/utilities/getUniqueContractAddress';
import { useChainId, useProvider, useSigner } from 'libs/wallet';
import { ChainId } from 'types';

export { abi as XvsVaultAbi } from 'libs/contracts/generated/infos/abis/XvsVault';

interface GetXvsVaultContractAddressInput {
  chainId: ChainId;
}

export const getXvsVaultContractAddress = ({
  chainId,
}: GetXvsVaultContractAddressInput) =>
  getUniqueContractAddress({ name: 'XvsVault', chainId });

export const useGetXvsVaultContractAddress = () => {
  const { chainId } = useChainId();

  return useMemo(
    () =>
      getXvsVaultContractAddress({
        chainId,
      }),
    [chainId],
  );
};

interface GetXvsVaultContractInput {
  chainId: ChainId;
  signerOrProvider: Signer | Provider;
}

export const getXvsVaultContract = ({
  chainId,
  signerOrProvider,
}: GetXvsVaultContractInput) => {
  const address = getXvsVaultContractAddress({ chainId });
  return address ? (new Contract(address, abi, signerOrProvider) as XvsVault) : undefined;
};

interface UseGetXvsVaultContractInput {
  passSigner?: boolean;
  chainId?: ChainId;
}

export const useGetXvsVaultContract = (input?: UseGetXvsVaultContractInput) => {
  const { chainId: currentChainId } = useChainId();
  const chainId = input?.chainId || currentChainId;

  const { provider } = useProvider({ chainId });
  const { signer } = useSigner({ chainId });
  const signerOrProvider = input?.passSigner ? signer : provider;

  return useMemo(
    () =>
      signerOrProvider
        ? getXvsVaultContract({
            chainId,
            signerOrProvider,
          })
        : undefined,
    [signerOrProvider, chainId],
  );
};

    