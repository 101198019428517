/* Automatically generated file, do not update manually */
    import type { Provider } from '@ethersproject/abstract-provider';
import { Contract, Signer } from 'ethers';
import { useMemo } from 'react';

import abi from 'libs/contracts/generated/infos/abis/JumpRateModel.json';
import { JumpRateModel } from 'libs/contracts/generated/infos/contractTypes';
import { useProvider, useSigner } from 'libs/wallet';
import { ChainId } from 'types';

export { abi as JumpRateModelAbi } from 'libs/contracts/generated/infos/abis/JumpRateModel';

interface GetJumpRateModelContractInput {
  address: string;
  signerOrProvider: Signer | Provider;
}

export const getJumpRateModelContract = ({ signerOrProvider, address }: GetJumpRateModelContractInput) =>
  new Contract(address, abi, signerOrProvider) as JumpRateModel;

interface UseGetJumpRateModelContractInput {
  passSigner: boolean;
  address: string;
  chainId?: ChainId;
}

export const useGetJumpRateModelContract = ({
  passSigner = false,
  address,
  chainId,
}: UseGetJumpRateModelContractInput) => {
  const { provider } = useProvider({ chainId });
  const { signer } = useSigner({ chainId });
  const signerOrProvider = passSigner ? signer : provider;

  return useMemo(
    () =>
      signerOrProvider
        ? getJumpRateModelContract({
            address,
            signerOrProvider,
          })
        : undefined,
    [signerOrProvider, address],
  );
};

    